<template>
    <div class="pl-store__news-wraper">
        <MainHeader />
        <CommonTop />
        <div class="pl-store__news-page-box">
            <div class="pl-store__news-content-wrap">
                <router-view />
            </div>
        </div>

    </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
export default {
    name: 'news',
    components: {
        MainHeader,
        CommonTop,
    },

}
</script>
<style lang="scss" scoped>
.pl-store__news-wraper {
    background: #f5f5f5;
}

.pl-store__news-page-box {
    padding: 20px 0;
    width: 1200px;
    margin: auto;

    .pl-store__news-content-wrap {
        //padding: 20px 0;
    }
}

::v-deep {
    .pl-store__acticle-module-title {
        margin-top: -30px;
    }
}
</style>
